
import { LoadingIndicator } from '@Wonder-Cave/ui';
import { ContactListStatusEnum, ExternalV1ContactList } from '@gr/shared/models';

interface IcontactListProgressBarProps {
  contactList: ExternalV1ContactList;
}

const ContactListClassificationProgressBar = ({ contactList }: IcontactListProgressBarProps): JSX.Element => {
  const formattedStatus = contactList.status.charAt(0) + contactList.status.substring(1).toLowerCase();
  return (
    <div className='w-60'>
      <div className="flex items-center ">
        <p>{formattedStatus}</p>
        {contactList.status === ContactListStatusEnum.CLASSIFYING &&
          <>
            {contactList.totalContactsClassified ?
              <div
                className={`flex ml-2 mt-0.5 grow basis-1/2 h-2 overflow-hidden text-xs rounded justify-start bg-light-gray dark:bg-slate-400`}
              >
                <>
                  <div
                    style={{
                      width: `${Math.ceil(((contactList.totalContactsClassified ?? 0) / (contactList.totalContacts)) * 100)}%`,
                    }}
                    className={`flex flex-col justify-center text-center text-white bg-grass shadow-none whitespace-nowrap`}
                  ></div>
                </>
              </div>
              : <div className='ml-4'><LoadingIndicator /></div>
            }
          </>
        }
      </div>
    </div>
  );
};

export default ContactListClassificationProgressBar;
