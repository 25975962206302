import { LogoutOptions, User } from '@auth0/auth0-react';
import { Auth0CreateUserRequest, Auth0GetUserDetailsRequest, Auth0UpdateUserRequest, Auth0UserDetails, Auth0UserMembership, IHttpResponse } from '@gr/shared/models';
import { axiosPost } from '../authAxios';
import { IUserForm } from '../components/AdminSettings/types';

export type Auth0Logout = (options?: LogoutOptions | undefined) => void;

export const auth0Scope = "openid profile email";
export const auth0Audience = "grassroots";

export interface Auth0LogoutRequest {
  auth0Logout: Auth0Logout;
  auth0User: User | undefined;
}

export function logout({ auth0Logout, auth0User }: Auth0LogoutRequest) {
  const org_id = auth0User?.org_id ?? undefined;
  auth0Logout({ returnTo: `${window.location.origin}?organization=${org_id}` });
}

export function getUserMemberships(auth0User: User | undefined): Auth0UserMembership[] {
  return auth0User?.app_metadata?.memberships ?? [];
}

export async function getUserDetails(request: Auth0GetUserDetailsRequest) {
  const response = await axiosPost<IHttpResponse<Auth0UserDetails>>('/auth0-get-user-details', request);
  return response.data.data;
}

export async function createUser(form: IUserForm) {
  const request: Auth0CreateUserRequest = {
    email: form.email.trim(),
    firstName: form.firstName.trim(),
    lastName: form.lastName.trim(),
    memberships: [
      {
        roles: [form.role.value],
        clickerGroupId: form.clickerGroupId ?? undefined,
        clickerGroupApplied: false
      },
    ],
    clientIds: form.clients.map(c => c.value)
  };

  await axiosPost<IHttpResponse<string>>('/auth0-create-user', request);
}

export async function updateUser(details: Auth0UserDetails, form: IUserForm) {
  const request: Auth0UpdateUserRequest = {
    user_id: details.user_id,
    email: form.email.trim(),
    firstName: form.firstName.trim(),
    lastName: form.lastName.trim(),
    memberships: [
      {
        roles: [form.role.value],
        clickerGroupId: form.clickerGroupId ?? undefined,
        clickerGroupApplied: false
      },
    ],
    clientIds: form.clients.map(c => c.value)
  };

  await axiosPost<IHttpResponse<string>>('/auth0-update-user', request);
}
