import { useAuth0, User } from '@auth0/auth0-react';
import { axiosPost } from '@gr/portal/authAxios';
import { NotificationType, useNotifications } from '@gr/portal/contexts/NotificationContext';
import useAssignedClients from '@gr/portal/hooks/useAssignedClients';
import useClickerGroups from '@gr/portal/hooks/useClickerGroups';
import { getUserDetails } from '@gr/portal/providers/auth0.service';
import { convertEnumToReadableString } from '@gr/portal/providers/utility.provider';
import { Auth0GetUserDetailsRequest, Auth0OrganizationName, Auth0UserDetails } from '@gr/shared/models';
import { getAuth0Organization, getOrganizationLabelByOrg } from '@gr/shared/utils';
import { Button, ButtonVariantEnum, LogoLoadingIndicator, RightArrowIcon, Static, ToggleSwitch } from '@Wonder-Cave/ui';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

function getOrganization(user?: Auth0UserDetails, auth0User?: User) {
  const organization: Auth0OrganizationName = auth0User?.organization_name;
  return user ? getUserOrganization(user, organization) : organization;
}

function getUserOrganization(user: Auth0UserDetails, organization: Auth0OrganizationName) {
  const memberships = user?.app_metadata?.memberships ?? [];

  const match = memberships.find((m) => m?.organization === organization)?.organization;
  const first = memberships?.[0]?.organization;

  return match ?? first;
}

const ViewUser = () => {
  const { id } = useParams<any>();
  const [user, setUser] = useState<Auth0UserDetails>();
  const { user: auth0User } = useAuth0();
  const history = useHistory();
  const [resendLoading, setResendLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [resetMfaLoading, setResetMfaLoading] = useState(false);
  const { addNotification } = useNotifications();

  const organization = getOrganization(user, auth0User);
  const organizationLabel = getOrganizationLabelByOrg(organization);

  const [{ data: clickerGroups, loading: clickerDataLoading, error: clickerDataError }] = useClickerGroups();
  const [{ data: assignedClientData, loading: clientsLoading, error }, refetchAssignedClients] = useAssignedClients({
    userId: id,
  });

  const getUser = async (id: string) => {
    const request: Auth0GetUserDetailsRequest = { user_id: id ?? '' };
    const user = await getUserDetails(request);
    await refetchAssignedClients({
      params: {
        userId: id,
      },
    });
    setUser(user);
  };

  useEffect(() => {
    getUser(id);
  }, [id]);

  const resendEmail = async () => {
    try {
      setResendLoading(true);
      await axiosPost('/auth0-send-user-welcome-email', { user_id: id });
      addNotification({
        content: 'Email successfully sent.',
        type: NotificationType.SUCCESS,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setResendLoading(false);
    }
  };

  const removeUserFromOrganization = async () => {
    try {
      setRemoveLoading(true);
      await axiosPost('/auth0-remove-user-membership', { user_id: id, organization: getAuth0Organization() });
      addNotification({
        content: 'User successfully removed.',
        type: NotificationType.SUCCESS,
      });
      history.push('/app/users');
    } catch (e) {
      console.error(e);
    } finally {
      setRemoveLoading(false);
    }
  };

  const resetMfa = async () => {
    try {
      setResetMfaLoading(true);
      await axiosPost('/auth0-reset-mfa', { user_id: id });
      addNotification({
        content: 'Reset MFA Email successfully sent.',
        type: NotificationType.SUCCESS,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setResetMfaLoading(false);
    }
  };

  return <div>
    {user ? <div className='px-24 pt-8'>
      <div className='flex justify-between'>
        <h1>{user?.given_name + ' ' + user?.family_name}</h1>
        <div>
          <Button className='mr-4' isLoading={removeLoading} onClick={removeUserFromOrganization} variant={ButtonVariantEnum.DELETE}>DELETE</Button>
          <Button className='mr-4' isLoading={resetMfaLoading} onClick={resetMfa} variant={ButtonVariantEnum.SECONDARY}>RESET MFA</Button>
          <Button className='mr-4' variant={ButtonVariantEnum.TERTIARY} onClick={() => history.push(`/app/users/${id}/edit`)}>EDIT USER</Button>
        </div>
      </div>
      <div className='flex pt-16'>
        <Static className='basis-1/5 grow-0 shrink-0' title='FIRST NAME'>{user?.given_name}</Static>
        <Static className='basis-1/5 grow-0 shrink-0' title='LAST NAME'>{user?.family_name}</Static>
        <Static className='basis-2/5 grow-0 shrink-0' title='EMAIL'>{user?.email}</Static>
        <Static className='basis-1/5 grow-0 shrink-0' title='STATUS'><ToggleSwitch disabled checkedColor='bg-grass' checkedLabel checked={!user?.blocked} onChange={() => { }} /></Static>
      </div>
      <div className='flex pt-8'>
        <div className='basis-1/5 grow-0 shrink-0'>
          <Static className='basis-1/5 grow-0 shrink-0' title='ROLE'>{convertEnumToReadableString(user?.app_metadata?.memberships?.[0].roles?.[0])}</Static>
          <Button
            className='mt-16'
            trailingIcon={<RightArrowIcon />}
            variant={ButtonVariantEnum.GREEN}
            onClick={resendEmail}
            type="button"
            isLoading={resendLoading}
          >Resend Email</Button>
        </div>
        <Static className='basis-2/5 grow-0 shrink-0' title='AGENT GROUP'>{clickerGroups?.data?.find(cg => cg.id === user?.clickerGroupId)?.name ?? '-'}</Static>
        <Static className='basis-1/5 grow-0 shrink-0' title=''></Static>
        <Static className='basis-1/5 grow-0 shrink-0' title='CLIENTS'>{assignedClientData?.data?.map((client, index) => { return <div>{index + 1}. {client.name}</div>; })}</Static>
      </div>
    </div> : <div className='flex items-center justify-center pt-[22%]'><LogoLoadingIndicator /></div>}
  </div>;

};

export default ViewUser;