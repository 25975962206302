export enum BUCKETS {
  EXPORTS = 'exports',
  MMS = 'mms-uploads',
  OPTOUTS = 'optouts',
  OPTOUTS_EXTERNAL = 'optout-uploads-external',
  OPTOUTS_UNDO = 'optout-undo',
  CONTACTS = 'contact-uploads',
  SUPPRESSIONS = 'suppression-uploads',
  LINK_ORDERS = 'link-orders',
  GENERATED_LINKS = 'generated-links',
  ARCHIVE_S3 = 's3-bucket-archive',
  REPORTS = 'reports',
  REBRANDLY_LINK_CLICKS = 'rebrandly-link-clicks'
};
